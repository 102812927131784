<template>
  <!-- Navbar start -->
  <nav :class="{ 'bg-opacity-80 shadow-md transition-opacity': isScrolled || showMenu, '': !isScrolled && !showMenu }"
    class="fixed z-40 -mt-0 sm:-mt-2  w-full bg-white/10 p-2 transition-all md:px-5 lg:px-8" style="
      background: linear-gradient(to top, #ffffff15, #d9d9d90b);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
    ">
    <div class="flex justify-between max-w-[1600px]  mx-auto ">
      <a @click="showMenu = false" href="#main" class="lg:pl-4">
        <div class="flex h-full items-center justify-between">
          <img v-motion-pop-visible src="@/assets/twinslots-logo.png"
            class="mt-1 h-[45px] md:h-[50px] w-auto object-contain" alt="Twinslots Logo" />
        </div>
      </a>

      <button role="menubar" class="lg:hidden" @click="toggleMenu()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="h-8 w-9">
          <path fill-rule="evenodd"
            d="M3 6.75A.75.75 0 0 1 3.75 6h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 6.75ZM3 12a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75A.75.75 0 0 1 3 12Zm0 5.25a.75.75 0 0 1 .75-.75h16.5a.75.75 0 0 1 0 1.5H3.75a.75.75 0 0 1-.75-.75Z"
            clip-rule="evenodd" />
        </svg>
      </button>

      <div :class="{
        'bg-white/30 bg-opacity-80 lg:bg-transparent': isScrolled || showMenu,
        'bg-opacity-80': !isScrolled && !showMenu,
        hidden: !showMenu && isMobile(),
        flex: showMenu || !isMobile(),
      }"
        class=" lg:item-center absolute left-0 right-0 mt-14 flex-col items-start justify-end gap-4 p-2 shadow transition-all md:mt-14 lg:static lg:mt-2 lg:flex lg:translate-y-0 lg:flex-row lg:shadow-none"
        role="menu" :aria-expanded="showMenu.toString()">
        <div v-motion-pop-visible
          class="font-roboto-condensed flex flex-col justify-evenly gap-[20px] whitespace-nowrap font-inter text-lg text-[25px] font-normal text-white lg:mb-0 lg:flex-row">
          <a href="#home" @click="showMenu = false" role="menuitem"
            class="routerBtn rounded-2xl px-4 py-1.5 text-lg font-semibold transition-colors">
            Home
          </a>
          <a href="#promotions" @click="showMenu = false" role="menuitem"
            class="routerBtn rounded-2xl px-4 py-1.5 text-lg font-semibold capitalize transition-colors">
            Promotions
          </a>
          <a href="#socials" @click="showMenu = false" role="menuitem"
            class="routerBtn rounded-2xl px-4 py-1.5 text-lg font-semibold transition-colors">
            Socials
          </a>
          <a href="#leaderboard" @click="showMenu = false" role="menuitem"
            class="routerBtn rounded-2xl px-4 py-1.5 text-lg font-semibold transition-colors">
            Leaderboard
          </a>
          <a href="#challenges" @click="showMenu = false" role="menuitem"
            class="routerBtn rounded-2xl px-4 py-1.5 text-lg font-semibold transition-colors">
            Challenges
          </a>
        </div>
      </div>
    </div>
  </nav>
  <!-- Navbar end -->
</template>

<script>
export default {
  data() {
    return {
      isScrolled: false,
      isLogout: false,
      showMenu: false,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 0;

      if (this.showMenu && window.innerWidth < 768) {
        this.isScrolled = true;
      }
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
      if (this.showMenu && window.innerWidth < 768) {
        this.isScrolled = true;
      } else if (!this.showMenu && window.scrollY === 0) {
        this.isScrolled = false;
      }
    },
    isMobile() {
      return window.innerWidth < 1024; // Adjust based on your 'lg' breakpoint in Tailwind CSS config
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>
