<template>
  <div v-motion-pop-visible
    class="z-20 flex h-[70px] w-[70px] flex-col items-center justify-center rounded-2xl border-2 border-[#FFC125] bg-gradient-to-t from-[#FFFFFF15] to-[#D9D9D90B] font-oxanium"
    v-for="(unit, index) in timeUnits" :key="index" style="
            background: linear-gradient(to top, #ffffff15, #d9d9d90b);
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
          ">
    <h4 class="pt-1 text-4xl font-bold text-white">
      {{ unit.value }}
    </h4>
    <h6 class="text-sm font-bold uppercase text-white">
      {{ unit.label }}
    </h6>
  </div>
</template>

<script>
export default {
  props: {
    roobetTimer: {
      type: String, // Timer string in format "5d 1h 40m 37s"
      required: true,
    },
  },
  computed: {
    timeUnits() {
      const regex = /(\d+)([dhms])/g;
      const labels = { d: 'DAYS', h: 'HRS', m: 'MIN', s: 'SEC' };

      const units = [];
      let match;
      while ((match = regex.exec(this.roobetTimer)) !== null) {
        const value = match[1];
        const unit = match[2];
        units.push({ value, label: labels[unit] });
      }
      return units;
    },
  },
};
</script>
