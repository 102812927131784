<template>
  <section>
    <div>
      <div class="mt-10 flex flex-col items-center justify-center">
        <!-- <img loading="lazy" src="../assets/oeg-800.png" class="w-[400px]" alt="oeg"> -->
        <div class="mx-auto text-[50px] text-white" style="font-family: Acme, sans-serif; line-height: 0.8">
          Page Not Found
        </div>
        <div class="mx-auto mt-8 text-lg text-white" style="font-family: Acme, sans-serif; line-height: 0.8">
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </div>
        <div class="mt-10">
          <router-link to="/" class="bg-[#FDD79F] px-4 py-3 uppercase text-black" style="font-family: Acme, sans-serif">
            Back to Home
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style scoped></style>
