<template>
  <section id="promotions" class="relative z-10 py-24">
    <div class="relative px-4  sm:max-w-4xl lg:max-w-6xl 2xl:max-w-7xl mx-auto">
      <div class="flex justify-center items-center flex-col gap-2">
        <img v-motion-pop-visible class="max-w-[300px] object-contain" src="@/assets/hero-twinslots.png"
          alt="Twinslots word image" />
        <h2 v-motion-pop-visible class="mb-6 font-normal text-white text-5xl">PROMOTIONS</h2>
      </div>

      <!-- Promo Card -->
      <div
        class="z-20 relative py-8 text-center p-4 rounded-[50px] min-w-[300px] border-[6px] border-[#FFC125]/80  shadow-[0_0_15px_5px_#FFC125] overflow-hidden">
        <img class="sm:hidden absolute inset-0 z-0 h-full w-full object-cover " src="@/assets/promo-card-mobile-bg.png"
          alt="promo mobile background" />
        <img class="hidden sm:block absolute inset-0 z-0 h-full w-full object-cover " src="@/assets/promo-card-bg.png"
          alt="promo background" />

        <!-- Content -->
        <div class="relative z-10">
          <h2 class="lg:text-left text-3xl lg:text-4xl 2xl:text-5xl font-normal text-white">
            Use code <span class="text-[#FFC125]">TWIN</span> on your first bet and win big rewards!
          </h2>
          <div class="flex flex-col lg:flex-row justify-center lg:justify-between">
            <!-- Promo Details -->
            <div>
              <p class="py-4 text-lg text-white text-center lg:text-left">
                First-time bettors to use the code will receive the following:
              </p>
              <div v-for="(promo, idx) in promoList" :key="idx"
                class="flex justify-center lg:justify-start items-center pl-8">
                <p class="w-56 text-2xl text-white uppercase">
                  WAGER <span class="text-[#FFC125]">{{ promo.wager }}</span> AND Earn
                </p>
                <img class="h-12" :src="promo.img" :alt="`Promo for wagering ${promo.wager}`" />
              </div>
              <p class="py-4 text-lg text-white lg:text-left">
                For every <span class="text-[#FFC125]">$200,000</span> wagered, receive a
                <span class="text-[#FFC125]">$100</span> bonus and unlock an
                <span class="text-[#FFC125]">exclusive ticket</span> on Discord!
              </p>
            </div>

            <!-- Promo Call-to-Action -->
            <div class="hidden lg:flex relative w-[320px] ml-auto flex-col items-center justify-end">
              <div class="-mt-16 top-0 absolute flex justify-center w-full">
                <img v-motion-pop-visible class="h-[135px] object-contain" src="@/assets/promo-chicken.png"
                  alt="chicken" />
              </div>
              <div v-motion-pop-visible class="flex justify-center items-center gap-4">
                <p class="text-2xl text-white">REGISTER ON</p>
                <img class="h-[33px]" src="@/assets/promo-roobet.png" alt="roobet" />
              </div>
              <div v-motion-pop-visible class="py-2">
                <h2 class="text-5xl text-white">USE CODE</h2>
                <h1 class="text-7xl text-[#FFC125]">TWIN</h1>
                <h2 class="text-5xl text-white">to support us!</h2>
              </div>
              <button v-motion-pop-visible @mouseover="hoveredButton1 = true" @mouseleave="hoveredButton1 = false"
                class="relative overflow-hidden rounded-3xl font-bold transition-transform duration-300 hover:scale-105 hover:shadow-lg h-[82px] max-w-[320px] w-full">
                <img
                  :src="hoveredButton1 ? require('@/assets/hero-hover-cta-1.png') : require('@/assets/hero-idle-cta-1.png')"
                  alt="Register on Roobet.com button background"
                  class="absolute inset-0 z-0 h-full w-full object-cover transition-opacity duration-300" />
                <a href="https://roobet.com/?ref=twin"
                  class="relative z-10 text-center font-lato font-bold text-[#19034C] text-lg">
                  Register on Roobet.com
                </a>
              </button>
            </div>
          </div>
          <!-- Mobile Button -->
          <button v-motion-pop-visible @mouseover="hoveredButton1 = true" @mouseleave="hoveredButton1 = false"
            class="lg:hidden relative overflow-hidden rounded-3xl font-bold transition-transform duration-300 hover:scale-105 hover:shadow-lg h-[82px] max-w-[320px] w-full">
            <img
              :src="hoveredButton1 ? require('@/assets/hero-hover-cta-1.png') : require('@/assets/hero-idle-cta-1.png')"
              alt="Register on Roobet.com button background"
              class="absolute inset-0 z-0 h-full w-full object-cover transition-opacity duration-300" />
            <a href="https://roobet.com/?ref=twin"
              class="relative z-10 text-center font-lato font-bold text-[#19034C] text-lg">
              Register on Roobet.com
            </a>
          </button>
        </div>
      </div>
      <!-- Roobet Rules -->
      <br>
      <br>
      <p class=" text-white text-2xl  font-bebas ">
        Your wagers on Roobet will count towards the leaderboard at the following weights based on the games you are
        playing. This helps prevent leaderboard abuse:
        <br>
        <br>
        *Games with an RTP under 96% will contribute 100% of the amount wagered to the leaderboard.
        <br>
        *Games with an RTP of 97% will contribute 50% of the amount wagered to the leaderboard.
        <br>
        *Games with an RTP of 98- 99% and above will contribute 10% of the amount wagered to the leaderboard.
        <br>
        *Only house games and slots count towards leaderboard.
      </p>
    </div>

    <img v-motion-pop-visible class="absolute -right-6 bottom-0 w-[13%] z-0" src="@/assets/promo-planet.png"
      alt="rocket" />
  </section>
</template>

<script>
import { ref } from 'vue';

export default {
  data() {
    return {
      promoList: [
        { wager: '$2,500', img: require('@/assets/promo-earn-10.png') },
        { wager: '$10,000', img: require('@/assets/promo-earn-25.png') },
        { wager: '$25,000', img: require('@/assets/promo-earn-50.png') },
        { wager: '$50,000', img: require('@/assets/promo-earn-100.png') },
        { wager: '$100,000', img: require('@/assets/promo-earn-200.png') },
      ],
      hoveredButton1: ref(false),
    };
  },
};
</script>
