<template>
  <!-- <footer class="bg-white p-6 sm:px-8 lg:px-16 xl:px-24 w-full py-16 shadow-inner text-center"> -->
  <footer class="w-full bg-white p-2  px-5 py-8 pb-16 text-center transition-all md:px-5 lg:px-8">
    <!-- <section id="socials" class="p-2 sm:px-4 md:px-8 lg:px-16 xl:px-24 w-full py-24 shadow-inner text-center"> -->

    <div class="max-w-[1600px]  mx-auto lg:px-4">
      <div class="flex flex-col items-center justify-between gap-8 md:flex-row">
        <!-- Logo Section -->
        <div class="flex-shrink-0">
          <!-- <img v-motion-pop-visible src="@/assets/footer-logo.png" alt="Twinslot logo" class="w-36 sm:w-48 lg:w-56" /> -->
          <!-- <img v-motion-pop-visible src="@/assets/twinslots-logo.png" class="w-full max-w-[197px]" alt="logo" /> -->
          <!-- <img v-motion-pop-visible src="@/assets/twinslots-logo.png" alt="Twinslots logo"
            class="w-auto h-auto max-w-[150px] sm:max-w-[200px] lg:max-w-[250px] object-contain" /> -->
          <img v-motion-pop-visible src="@/assets/twinslots-logo.png"
            class="mt-1 h-[40px] md:h-[50px] w-auto object-contain" alt="Twinslots Logo" />
        </div>

        <!-- Navigation Links -->
        <nav v-motion-pop-visible class="flex flex-col flex-wrap justify-center gap-2 lg:flex-row sm:gap-4 lg:gap-10">
          <a v-for="(item, key) in navList" :key="key" :href="item.hash"
            class="font-inter text-lg font-medium text-black transition-colors hover:text-[#FCC027]">
            {{ item.name }}
          </a>
        </nav>

        <!-- Social Media Icons -->
        <div v-motion-pop-visible class="flex  justify-center gap-2">
          <a v-for="(social, idx) in socialIcons" :key="idx" :href="social.url" target="_blank"
            rel="noopener noreferrer"
            class="flex h-8 w-8 transform items-center justify-center transition-transform hover:scale-110"
            :aria-label="`Visit our ${social.alt} page`">
            <img :src="social.img" :alt="social.alt" class="h-full w-full object-contain" />
          </a>
        </div>
      </div>


      <div class="flex w-full flex-col gap-8 pt-8 text-center font-inter md:flex-row md:text-left">
        <!-- Column 1 -->
        <div v-motion-pop-visible class="w-full md:w-1/2">
          <p class="text-base text-gray-800">
            If you ever feel that gambling is becoming a problem, remember that most people gamble for fun and
            enjoyment.
            However, some people may view gambling as a way to make money, spend more than they can afford, or use
            gambling to distract themselves from everyday problems.
          </p>
          <p class="pt-4 text-base text-gray-800">
            For further advice, help, and support, visit:
            <a class="text-[#009AFF] underline transition-colors hover:text-blue-600"
              href="https://www.begambleaware.org/" target="_blank" rel="noopener noreferrer">
              www.begambleaware.org
            </a>
          </p>
        </div>

        <!-- Column 2 -->
        <div v-motion-pop-visible class="w-full font-inter md:w-1/2">
          <p class="text-base text-gray-800">
            We do not take responsibility for misunderstood promotions or losses from gambling on casinos or betting
            sites
            linked to or promoted on this website. Ensure it is legal for you to use each site in your jurisdiction and
            ALWAYS gamble responsibly!
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { name: 'Home', hash: '#home' },
        { name: 'Promotions', hash: '#promotions' },
        { name: 'Socials', hash: '#socials' },
        { name: 'Leaderboards', hash: '#leaderboards' },
        { name: 'Challenges', hash: '#challenges' },
      ],
      socialIcons: [
        {
          url: 'https://x.com/Twinslots',
          img: require('@/assets/footer-x-icon.png'),
          alt: 'X',
        },
        {
          url: 'https://www.instagram.com/kicktwinslots/',
          img: require('@/assets/footer-insta-icon.png'),
          alt: 'Instagram',
        },
        {
          url: 'https://www.youtube.com/@KickTwinSlots',
          img: require('@/assets/footer-youtube-icon.png'),
          alt: 'YouTube',
        },
        {
          url: 'https://kick.com/twinslots',
          img: require('@/assets/footer-kicks-icon.png'),
          alt: 'Kick',
        },

        {
          url: 'http://discord.gg/twinslots',
          img: require('@/assets/footer-discord-icon.png'),
          alt: 'Discord',
        },
        {
          url: 'https://www.tiktok.com/@kicktwinslots',
          img: require('@/assets/footer-tiktok-icon.png'),
          alt: 'TikTok',
        },
      ],
    };
  },
};
</script>
