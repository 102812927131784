<template>
  <section id="socials"
    class="relative w-full p-2 py-24 pt-36 text-center sm:px-4 md:px-8 lg:px-16 xl:px-24">
    <img v-motion-pop-visible class="mx-auto h-[143px] w-auto max-w-full object-contain " src="@/assets/hero-twins.png"
      alt="twinslots  twins" />
    <img class="absolute top-0 w-full overflow-hidden bg-cover" src="@/assets/leaderboard-stars.png"
      alt="small stars" />
    <!-- Section Title -->
    <h2 v-motion-pop-visible class="mb-6 text-4xl font-normal text-white sm:text-5xl">Follow Our Socials</h2>
    <img v-motion-pop-visible class="absolute left-0 top-1/2 w-[15%]" src="@/assets/socmed-rocket.png" alt="rocket" />
    <!-- Social Icons -->
    <div v-motion-pop-visible class="mx-auto flex w-[90%] flex-wrap justify-center gap-6 sm:gap-4 lg:gap-8 py-3">
      <a v-for="(icon, index) in socialIcons" :key="index" :href="icon.url" target="_blank" rel="noopener noreferrer"
        class="flex transform items-center justify-center transition-transform hover:scale-110">
        <img :src="icon.img" :alt="icon.alt" class="h-20 w-20 object-contain  lg:h-24 lg:w-24" />
      </a>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      socialIcons: [
        {
          url: 'https://www.youtube.com/@KickTwinSlots',
          img: require('@/assets/socmed-youtube-icon.png'),
          alt: 'Youtube',
        },
        {
          url: 'https://x.com/Twinslots',
          img: require('@/assets/socmed-x-icon.png'),
          alt: 'X',
        },
        {
          url: 'https://www.instagram.com/kicktwinslots/',
          img: require('@/assets/socmed-insta-icon.png'),
          alt: 'Instagram',
        },
        {
          url: 'https://www.tiktok.com/@kicktwinslots',
          img: require('@/assets/socmed-tiktok-icon.png'),
          alt: 'Tiktok',
        },
        // {
        //   url: '',
        //   img: require('@/assets/socmed-telegram-icon.png'),
        //   alt: 'Telegram',
        // },
        {
          url: 'https://kick.com/twinslots',
          img: require('@/assets/socmed-kicks-icon.png'),
          alt: 'Kick',
        },
        {
          url: 'http://discord.gg/twinslots',
          img: require('@/assets/socmed-discord-icon.png'),
          alt: 'Discord',
        },
      ],
    };
  },
};
</script>
