<template>
  <section
    class="relative min-h-screen max-h-[1230px] w-full overflow-hidden bg-[url('@/assets/hero-bg.png')] bg-cover bg-center bg-no-repeat">
    <!-- <img v-motion-pop-visible class="absolute top-1/3 -left-6 -z-10 mt-12 h-auto w-[20%] sm:top-1/3 sm:w-[10%]"
      src="@/assets/hero-planet.png" alt="violet planet" /> -->
    <img v-motion-pop-visible class="absolute top-1/3 -left-6 -z-0 mt-12 h-auto w-[20%] sm:top-1/3 sm:w-[10%]"
      src="@/assets/hero-planet.png" alt="violet planet" />
    <img v-motion-pop-visible class="absolute top-12 -right-10 h-auto w-[30%] sm:top-20 sm:right-2 sm:w-[10%]"
      src="@/assets/hero-comet.png" alt="comet" />
    <div class="h-full w-full text-center">
      <img v-motion-pop-visible
        class="mx-auto -mb-20 h-auto w-[90%] max-w-full object-contain sm:-mb-28 sm:w-[70%] md:w-[50%] lg:-mb-20 lg:w-[30%]"
        src="@/assets/hero-logo-icon-shadow.png" alt="twinslots logo icon" />

      <h1 v-motion-pop-visible
        class="lg:drop-shadow-3xl px-10 pb-2 text-4xl font-normal uppercase text-white drop-shadow-2xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl">
        Double the fun, double the bets with
      </h1>

      <img v-motion-pop-visible
        class="mx-auto h-auto w-[60%] max-w-full object-contain sm:w-[70%] md:w-[50%] lg:w-[30%]"
        src="@/assets/hero-twinslots.png" alt="twinslots word image" />

      <div class="flex flex-col items-center justify-center gap-4 pt-8 sm:flex-row xl:gap-8 2xl:gap-12 2xl:pt-10">
        <!-- Register on Roobet Button -->
        <button v-motion-pop-visible @mouseover="hoveredButton1 = true" @mouseleave="hoveredButton1 = false"
          class="relative mx-4 overflow-hidden rounded-2xl py-4 px-10 transition-transform duration-200 hover:scale-105 hover:shadow-lg sm:py-6 sm:px-14">
          <img
            :src="hoveredButton1 ? require('@/assets/hero-hover-cta-1.png') : require('@/assets/hero-idle-cta-1.png')"
            alt="Register on Roobet.com button background"
            class="absolute inset-0 z-0 h-full w-full rounded-2xl object-cover transition-opacity duration-200" />
          <a href="https://roobet.com/?ref=twin"
            class="relative z-10 font-lato text-sm font-bold text-[#19034C] sm:text-base lg:text-lg">
            Register on Roobet.com
          </a>
        </button>

        <!-- Watch Live on Kick Button -->
        <button v-motion-pop-visible @mouseover="hoveredButton2 = true" @mouseleave="hoveredButton2 = false"
          class="relative mx-4 overflow-hidden rounded-2xl py-4 px-10 transition-transform duration-200 hover:scale-105 hover:shadow-lg sm:py-6 sm:px-14">
          <img
            :src="hoveredButton2 ? require('@/assets/hero-hover-cta-2.png') : require('@/assets/hero-idle-cta-2.png')"
            alt="Watch Live on Kick.com button background"
            class="absolute inset-0 z-0 h-full w-full rounded-2xl object-cover transition-opacity duration-200" />
          <a href="https://kick.com/twinslots"
            class="relative z-10 font-lato text-sm font-bold text-[#19034C] sm:text-base lg:text-lg">
            Watch Live on Kick.com
          </a>
        </button>
      </div>
      <img v-motion-pop-visible class="mx-auto h-[190px] w-auto max-w-full object-contain pt-8 sm:hidden"
        src="@/assets/hero-twins.png" alt="twinslots  twins" />
    </div>
  </section>
</template>


<script>
import { ref } from 'vue';

export default {
  setup() {
    const hoveredButton1 = ref(false);
    const hoveredButton2 = ref(false);

    return {
      hoveredButton1,
      hoveredButton2,
    };
  },
};
</script>
